import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';

export default class FamilyMemberForm {
  constructor() {
    this.openEditMemberSelector = '#open-update-member';
    this.openNewMemberSelector = '#open-new-member';
    this.updateMemberFormSelector = '#family-details-form';
    this.newMemberFormSelector = '#new-mem-form';
    this.dobInputSelector = '#dob';
    this.ageShowSelector = '.input-age';
    this.familyMemberTypeSelector = '#family-member-type';
    this.standaloneInfoSelector = '.standalone';
    this.existingInfoSelector = '.linked';
    this.newContactFamilyMemberSelector = '#add-new-contact';
    this.newContactFormSelector = '#new-contact-form';
    this.toggleEmergencyNetworkSelector = '#toggle-emergency-network';
    this.emergencyNetwork = '#emergency-network';
    this.addMemberAsContactSelector = '#add-as-contact';
    this.relatedContactSelector = '#related-contact';
    this.relatedContactErrorSelector = '#related-contact-error';
    this.familyMemberFormBodySelector = '.family-member-form';
    this.sendCopyToNetworkSelector = '#send-copy-policy';
  }

  setup() {
    this.addEditButtonTriggerOpenModalEvents();
    this.handleOnFamilyMember();
    this.handleSubmitForm();
  }

  handleSubmitForm() {
    $('body').on('modal:afterSetContent', `${this.openNewMemberSelector}, ${this.newMemberFormSelector}`, () => {
      let backUrl = $('.reinit-container a.selection-item:first-child').attr("href");
      $('input[type="submit"]').attr('disabled', true);

      $('#new-mem-form').on('click', () => {
        $('input[type="submit"]').removeAttr('disabled');
      });

      $('#new-mem-form').on('submit', (event) => {
        setTimeout(() => {
          let errors = false;
          $('#new-mem-form').find('.invalid-feedback').each(function () {
            if ($(this).attr('id') != 'related-contact-error' && $(this).val().length < 1) {
              $(this).addClass('error');
              errors = true;
            }
          });

          if ($('.reinit-container a.selection-item:first-child').length < 1) { // lenght < 1 ==> without element back-link. So, how to add it as href here?
            $('.family-member-modal .reinit-container').prepend(`<a data-type="html" data-modal="true" class="selection-item" id="back-link" data-remote="true" href="${backUrl}"><div class="icon icon-sm mt-2 ml-2"><img alt="btn-icon" src="/packs/media/images/icons/arrow-left-f6a4eced0286db203356921385d11c72.svg"></div></a>`)
          }

          if (errors == false && (backUrl.includes('insurance_policies') || backUrl.includes('assets') || backUrl.includes('emergency_networks') || backUrl.includes('dreams/new'))) {
            $('.reinit-container a.selection-item:first-child')[0].click();
          }
        }, 1000);
      });
    });
  }

  addEditButtonTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', `${this.openEditMemberSelector}, ${this.updateMemberFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
    })
    $('body').on('modal:afterSetContent', `${this.openNewMemberSelector}, ${this.newMemberFormSelector}, ${this.openEditMemberSelector}, ${this.updateMemberFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
      this.toggleInputForm();
      this.toggleCorrespondencePrBaseOnNationality();
      this.handleChangeRelatedContact();
      this.customSelect2ForRelatedContact();
    })
  }

  toggleInputForm() {
    this.checkToggle();
    this.toggleEmergencyNetwork();

    $(this.familyMemberTypeSelector).on('change', () => {
      this.checkToggle();
    })

    $(this.toggleEmergencyNetworkSelector).on('change', () =>{
      this.toggleEmergencyNetwork();
    });
  }

  checkToggle() {
    if($(this.familyMemberTypeSelector).is(':checked')) {
      $(this.standaloneInfoSelector).hide();
      $(this.existingInfoSelector).show();
      $(this.sendCopyToNetworkSelector).prop('disabled', false);
    }
    else {
      $(this.standaloneInfoSelector).show();
      $(this.existingInfoSelector).hide();
      $(this.sendCopyToNetworkSelector).prop('disabled', 'disabled');
      $(this.sendCopyToNetworkSelector).prop('checked', false);
    }
  }

  toggleEmergencyNetwork() {
    if($(this.toggleEmergencyNetworkSelector).is(':checked')) {
      $(this.emergencyNetwork).show();
      $(this.sendCopyToNetworkSelector).parent().show();
    }
    else {
      $(this.emergencyNetwork).hide();
      $(this.sendCopyToNetworkSelector).parent().hide();
      $(this.sendCopyToNetworkSelector).prop('checked', false);
    }
  }

  handleOnFamilyMember() {
    $('body').on('modal:afterSetContent', `${this.newContactFamilyMemberSelector}, ${this.newContactFormSelector}`, () => {
      calculateAgeByInput(this.dobInputSelector, this.ageShowSelector);
    })

    $('body').on('modal:afterSetContent', `${this.openEditMemberSelector}, ${this.updateMemberFormSelector}, ${this.newMemberFormSelector}, ${this.openNewMemberSelector}`, () => {
      this.toggleEmergencyNetwork();
      this.handleSelectAllBtn();
      this.handleDeselectAllBtn();
    });
  }

  toggleCorrespondencePrBaseOnNationality() {
    $('#family_member_nationality').on('change', () => {
      const value = $('#family_member_nationality :selected').val();
      if (value == 'Singapore Citizen') {
        $('.family_member_singapore_pr').addClass('d-none');
      } else {
        $('.family_member_singapore_pr').removeClass('d-none');
      }
    });
  }

  handleChangeRelatedContact() {
    $(this.relatedContactSelector).on('change', (e) => {
      const clientId = $(this.familyMemberFormBodySelector).data('client-id');
      const clientIds = $(this.relatedContactSelector).data('client-ids');
      if(clientIds.includes(parseInt($(e.target).val()))) {
        $('input[type="submit"]').removeAttr('disabled');
        $(this.relatedContactErrorSelector).hide();
        return;
      }

      $.ajax({
        url: Routes.verify_username_consultant_client_family_index_path(clientId),
        type: 'GET',
        data: {
          username: $(e.target).find('option:selected').text(),
        },
        success: (data) => {
          $('input[type="submit"]').removeAttr('disabled');
          $(this.relatedContactErrorSelector).show();

          if(data['error']) {
            $(this.relatedContactErrorSelector).text(data['error']);
            $('input[type="submit"]').attr('disabled', true);
            $(this.relatedContactErrorSelector).show();
            $(this.relatedContactErrorSelector).removeClass('text-black');
          } else if(data['warning']) {
            $(this.relatedContactErrorSelector).text(data['warning']);
            $(this.relatedContactErrorSelector).addClass('text-black');

            $(e.target).find('option').last().val(data['client_id']);
          } else {
            $(this.relatedContactErrorSelector).hide();
          }
        },  
      });
    })
  }

  customSelect2ForRelatedContact() {
    $(this.relatedContactSelector).select2({
      minimumResultsForSearch: 0,
      tags: true,
    })
  }

  handleSelectAllBtn() {
    $('#select-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', true);
    });
  }

  handleDeselectAllBtn() {
    $('#deselect-all-btn').on('click', () => {
      $('.insurance-list input').prop('checked', false);
    });
  }
}
